
import Vue from "vue";

/**
 * Service Workerのイベントがインストール済みになったとき発動。ウインドウを強制リロードさせる
 */
if ("serviceWorker" in navigator) {
  let isControlled = Boolean(navigator.serviceWorker.controller);
  navigator.serviceWorker.addEventListener("controllerchange", () => {
    if (isControlled) {
      console.log("☆☆☆☆☆☆☆☆☆☆☆☆☆☆☆☆Page Reload☆☆☆☆☆☆☆☆☆☆☆☆☆☆☆☆☆☆☆☆");
      window.location.reload();
    } else {
      isControlled = true;
    }
  });

  navigator.serviceWorker.getRegistration().then(registration => {
    setInterval(async () => {
      console.count("service-worker:update");
      registration?.update();
    }, 1000);
  });
}

export default Vue.extend({
  name: "App",
  created() {
    /**
     * SWイベント取得。画面更新ダイアログ
     */
    document.addEventListener(
      "serviceWorkerUpdated",
      async e => {
        const event = e as CustomEvent<ServiceWorkerRegistration>;
        if (event && event.detail.waiting) {
          await this.$dialog("update");
          event.detail.waiting.postMessage({ type: "SKIP_WAITING" });
        }
      },
      { once: true }
    );
  }
});
